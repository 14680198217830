.custom-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #17A2B8;
  border-radius: 50%;
  position: relative;
  top: 3px;
  display: inline-block;
  height: 18px;
  width: 18px;
  animation: spin 1s ease-in-out infinite;
}

.transparent {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}

.solid {
  background-color: #4C63F5;
  /* Change the background color to your desired solid color */
  transition: background-color 0.3s ease;
}
.black-image {
  filter: invert(100%);
}