.custom-try-app {
    content: "";
    /* position: absolute; */
    width: 10px;
    height: 10px;
    /* top: 50%;
    left: 50%; */
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    background-color: #253551;
    transition: transform 0.3s ease-out;
    z-index: -1;
}

.custom-position-second-section {
    position: relative;
    top: -47vh;
    right: -5vw;
}


.custom-landing-heading {
    font-size: 60px;
}

.powerful-div {
    width: 700px;
    margin-top: 100px;
}

.fist-section-height {
    height: 600px;
}

@media (max-width: 600px) {
    .custom-landing-heading {
        font-size: 40px;
    }

    .powerful-div {
        width: 350px;
        margin-top: 100px;
    }

    .custom-position-explore {
        visibility: hidden;
    }

    .custom-image-margin {
        margin-top: 50px;
    }

    .nav-bg-image {
        visibility: hidden;
    }
}