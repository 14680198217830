.btn-outline-purple-custom {
  padding: 10px;
  color: #C55EDF;
  border: 1px solid #C55EDF;
}

.btn-outline-purple-custom:hover {
  color: #fff;
  background-color: #C55EDF;
  border-color: #C55EDF;
}

@media (max-width: 1272px) {
  /* .scale-down {
    scale: 50%;
  } */
}