.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.03); /* Fallback for browsers that do not support backdrop-filter */
     backdrop-filter: blur(2px); /*Add the backdrop filter for blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 25px;
}
